import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
let url= window.location.host;
let protocol= window.location.protocol;
if(window.location.hostname=='localhost'||window.location.hostname=='192.168.31.101'||window.location.hostname=='192.168.31.158'){
  url='rijitongadmin.com'
    // url='riya.xin'
}else{
  url= url.replace(/^www\./i, ''); 
}
export default new Vuex.Store({
  state: {
    config:JSON.parse(localStorage.getItem("localconfig"))?JSON.parse(localStorage.getItem("localconfig")):null,//网站基础配置z
    statedeflang:JSON.parse(localStorage.getItem("deflang"))?JSON.parse(localStorage.getItem("deflang")):null,//当前展示语言
    statedefrate:JSON.parse(localStorage.getItem("defrate"))?JSON.parse(localStorage.getItem("defrate")):null,//当前展示货币
    // ripaigoapiurl: protocol+'//jpapicnhk.zzqss.com',//(日拍购)香港正式请求域名 (舍弃)
    xgapiurl: protocol+'//domainhkjpapi.zzqss.com',//香港一正式接口请求域名
    xgTwoapiurl: protocol+'//mainhkjpapi.zzqss.com',//香港二正式接口请求域名
    xgThreeapiurl: protocol+'//jpzapi.zzqss.com',//168正式接口请求域名(之前的168域名移到了香港)
    beijixingapiurl: 'https://admin.beijixingbuy.com',//北极星请求域名
    apiurl: protocol+'//domainhkjpapi.zzqss.com',//香港一正式接口请求域名
    rijitongApiurl: 'http://rijitongadmin.com',//rijitong正式接口请求域名
    gobuyapiurl: protocol+'//gobuyadmin.zzqss.com',//gobuy专用接口请求域名
    otherapiurl:'https://jpapiproject.zzqss.shop',//168接口请求域名 勿动
    usertoken:JSON.parse(localStorage.getItem("usertoken"))?JSON.parse(localStorage.getItem("usertoken")):null,//获取用户token
    userdata:JSON.parse(localStorage.getItem("userdata"))?JSON.parse(localStorage.getItem("userdata")):null,//获取用户信息
    cartnum:'0',//购物车数量
    unpaidPayment:'0',//未付款数量
    isWeixin:navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1, // 是否在微信浏览器中  false 不是
    isSafari:/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent), // 是否在safari浏览器中  false 不是
    uploadurl:'',//图片上传方法里的返回图片
    langJson:JSON.parse(localStorage.getItem("langJson"))?JSON.parse(localStorage.getItem("langJson")):null,//语言包列表
    localDomain:url,//header里的webdomain 用于传递给接口返回 webhost/domain_url
    localDomainStorage:localStorage.getItem("localDomainStorage")?localStorage.getItem("localDomainStorage"):'',//存在本地的当前访问域名，和新输入的域名作比较
    website_domain:window.location.href,//网站直接域名
    webHost:localStorage.getItem("webHost")?localStorage.getItem("webHost"):'',//根据接口返回网站webhost，传递进header 区别调用哪个数据库
    router_domain_url:localStorage.getItem("router_domain_url")?localStorage.getItem("router_domain_url"):'',//根据接口返回domainurl 判断进入哪个路由文件
    theme:'default',//预留网站模版参数 前期走默认
    hasMessage:false,//网站是否有新消息
    hasMessagenum:0,//网站是否有新消息
    allOverlay:false,//全局loading遮罩层
    goodsPriceUnit:'円',
    showZhSearch:true, // 是否显示中文搜索
    navigateType:JSON.parse(sessionStorage.getItem("navigateType"))?JSON.parse(sessionStorage.getItem("navigateType")):null,//当前选中的哪个站点信息
    historyData:localStorage.getItem("history")?JSON.parse(localStorage.getItem("history")):[],//搜索历史
    mybondmsg:null,//auc保证金额度,
    lazySrcImg:require('@/assets/images/detail/img_null.png'), // 未加载出来的图片
    prohibitedSrcImg:require('@/assets/images/detail/stop.png'), // 未加载出来的图片
    exchangeRate:false,//是否显示wakuwakubuy的不同汇率
    cacheRecord:localStorage.getItem("historyRecord")?JSON.parse(localStorage.getItem("historyRecord")):[],//缓存浏览记录
    gobuyrates: localStorage.getItem("exchangeRate") ? JSON.parse(localStorage.getItem("exchangeRate")) : null, //周总多货币选择
    cacheRecord: localStorage.getItem("historyRecord") ? JSON.parse(localStorage.getItem("historyRecord")) : [],//缓存浏览记录
    webShowType: '0',//是否展示导航分类
    topUpParams: null, // 储存buygo 余额不足支付的信息
  },
  mutations: {
    setgoodsPriceUnit (state, data) {
      state.goodsPriceUnit = data
    },
    setmybondmsg (state, data) {
      state.mybondmsg = data
    },
    setconfig (state, data) {
      localStorage.setItem('localconfig', JSON.stringify(data))// 存列表
      state.config = data
    },
    setallOverlay (state, data) {
      state.allOverlay = data
    },
    sethasMessage (state, data) {
      state.hasMessage = data
    },
    sethasMessagenum (state, data) {//网站新消息数量
      state.hasMessagenum = data
    },
    setdeflang (state, data) {
      localStorage.setItem('deflang', JSON.stringify(data))// 设置当前展示语言
      state.statedeflang = data
      // if(data){
      //   window.location.reload();
      // }
    },
    setdefrate (state, data) {
      localStorage.setItem('defrate', JSON.stringify(data))// 设置当前展示货币
      state.statedefrate = data
    },
    setLangJson (state, data) {
      localStorage.setItem('langJson', JSON.stringify(data))// 设置网站语言包数据
      state.langJson = data
    },
    setusertoken (state, data) {
      if (data === null) {
        localStorage.removeItem('usertoken')
        state.usertoken = null
      } else {
        localStorage.setItem('usertoken', JSON.stringify(data))
        state.usertoken = JSON.parse(localStorage.getItem('usertoken'))
      }
    },
    setuserdata (state, data) {
      if (data === null) {
        localStorage.removeItem('userdata')
        state.userdata = null
      } else {
        localStorage.setItem('userdata', JSON.stringify(data))
        state.userdata = JSON.parse(localStorage.getItem('userdata'))
      }
    },
    setcartnum (state, data) {
      state.cartnum = data
    },
    setUnpaidPayment(state,data){
      state.unpaidPayment=data
    },
    setuploadurl (state, data) {
      state.uploadurl = data
    },

    setrouterdomain_url (state, data) { // 设置路由跳转路径
      state.router_domain_url = data
      localStorage.setItem('router_domain_url', data)
    },
    setwebHost (state, data) {
      state.webHost = data
      localStorage.setItem('webHost', data)
    },

    setlocalDomainStorage (state, data) {
      state.localDomainStorage = data
      localStorage.setItem('localDomainStorage', data)
    },
    setnavigateType (state, data) {
      state.navigateType = data
      sessionStorage.setItem('navigateType', JSON.stringify(data))
    },
    setshowZhSearch (state, data) { // 是否显示中文搜索
		  state.showZhSearch = data
		},
    setHistory (state, data) { // 搜索历史
      state.historyData = data
      localStorage.setItem('history', JSON.stringify(data))
    },
    setRecord(state,data){  //缓存浏览历史
      state.cacheRecord=data;
      localStorage.setItem('historyRecord',JSON.stringify(data))
    },
    setlazySrcImg(state,data){  //占位图片
      state.lazySrcImg=data;
    },
    setRate (state, data) {
			state.exchangeRate = data // 是否wakuwaku使用新汇率
		},
    setGobuyRate(state, rate) {
      localStorage.setItem("exchangeRate",JSON.stringify(rate))
			state.gobuyrates = rate  //周总多货币
		},
    setwebShowType(state, data) {  //是否展示分类
      state.webShowType = data;
    },
    setTopUpParams(state, params) {
      state.topUpParams = params;
    } //存储余额不足支付信息
  },
  actions: {
    checkout ({ commit, state }, products) {
      // 把当前购物车的物品备份起来
      const savedCartItems = [...state.cart.added]
      // 发出结账请求，然后乐观地清空购物车
      commit(types.CHECKOUT_REQUEST)
      // 购物 API 接受一个成功回调和一个失败回调
      shop.buyProducts(
        products,
        // 成功操作
        () => commit(types.CHECKOUT_SUCCESS),
        // 失败操作
        () => commit(types.CHECKOUT_FAILURE, savedCartItems),
      )
    },
    getUser (context) {
      context.commit('setusertoken')
    },
  },
  modules: {

  },
})
